import { Point } from './geometry/Point';
import { Line } from './geometry/Line';
export class LineMaker {
    points;
    limit;
    constructor(points, limit) {
        this.points = points;
        this.limit = limit;
    }
    record(x, y) {
        return new LineMaker([...this.points, new Point(x, y)], this.limit);
    }
    reset() {
        return new LineMaker([], this.limit);
    }
    intersectsAll(circles) {
        return circles.every(circle => this.lines.some(line => circle.intersects(line)));
    }
    get lines() {
        const lines = [];
        for (let i = 0; i < this.points.length - 1; ++i) {
            lines.push(Line.fromEndpoints(this.points[i], this.points[i + 1]));
        }
        return lines;
    }
    get lastPoint() {
        return this.points[this.points.length - 1];
    }
    get atLimit() {
        return this.points.length >= this.limit + 1;
    }
}
