export var StatusType;
(function (StatusType) {
    StatusType["Nothing"] = "nothing";
    StatusType["Success"] = "success";
    StatusType["Failure"] = "failure";
})(StatusType || (StatusType = {}));
export class Status {
    type;
    message;
    constructor(type, message) {
        this.type = type;
        this.message = message;
    }
    static nothing = () => new Status(StatusType.Nothing, '');
    static succeed = (message) => new Status(StatusType.Success, message);
    static fail = (message) => new Status(StatusType.Failure, message);
}
