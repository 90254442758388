import { Angle } from './Angle';
export class Line {
    origin;
    length;
    angle;
    constructor(origin, length, angle) {
        this.origin = origin;
        this.length = length;
        this.angle = angle;
    }
    dot(other) {
        const ra = this.length;
        const rb = other.length;
        const ta = this.angle.radians;
        const tb = other.angle.radians;
        return ra * rb * Math.cos(ta - tb);
    }
    static fromEndpoints(start, end) {
        const sq = (n) => n * n;
        const length = Math.sqrt(sq(end.x - start.x) + sq(end.y - start.y));
        const radians = Math.atan2(end.y - start.y, end.x - start.x);
        return new Line(start, length, new Angle(radians));
    }
}
