export class Canvas {
    canvas;
    bitmap;
    constructor(canvas, bitmap) {
        this.canvas = canvas;
        this.bitmap = bitmap;
    }
    get context() {
        return this.canvas.getContext('2d');
    }
    get base64() {
        return this.canvas.toDataURL();
    }
    get imageData() {
        return this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
    }
    reset() {
        this.context.drawImage(this.bitmap ?? new ImageBitmap(), 0, 0);
        return new Canvas(this.canvas, this.bitmap);
    }
    write(data) {
        this.context.putImageData(data, 0, 0);
        return new Canvas(this.canvas, this.bitmap);
    }
    async upload(source) {
        const ctx = this.canvas.getContext('2d');
        const bitmap = await createImageBitmap(source);
        this.canvas.width = bitmap.width;
        this.canvas.height = bitmap.height;
        ctx.drawImage(bitmap, 0, 0);
        return new Canvas(this.canvas, bitmap);
    }
}
