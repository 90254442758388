import { quadOut } from 'svelte/easing';
import { forCustomElement } from '@auroratide/svelte-custom-element-transitions';
export const burst = forCustomElement((node, options) => {
    return {
        duration: options.duration,
        css: (t) => {
            const eased = quadOut(t);
            return `
                box-shadow: 0 0 ${1 + 4 * eased}em ${25 * eased}em var(--burst-color);
                opacity: ${1 - eased};
            `;
        },
    };
});
